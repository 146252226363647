<!--
<h2>ConnectUC SMS</h2>
<hr class="my-2" />
-->

<h3>JustCall</h3>

<table class="mt-1 mb-4">
    <colgroup>
        <col style="width: 200px;" />
        <col style="width: 500px;" />
    </colgroup>
    <tbody>

        <tr>
            <td>API Key:</td>
            <td>
                <!--type="text"-->
                <input type="password" class="form-control" [(ngModel)]="justCallSettings.apiKey" />
            </td>
        </tr>
        
        <tr>
            <td>API Secret:</td>
            <td>
                <input type="password" class="form-control" [(ngModel)]="justCallSettings.apiSecret" />
            </td>
        </tr>
        
        <tr>
            <td>From Phone Number:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="justCallSettings.fromPhoneNumber" />
            </td>
        </tr>
        
    </tbody>
</table>

<h3>Masters Telecom (API19)</h3>

<div class="alert alert-info" style="color: #25414a !important;">
    This texting service is currently unused.
</div>

<p class="mb-1">Also known as:</p>
<ul>
    <li>"Verizon Texting"</li>
    <li>ConnectUC</li>
    <li>Cloud Softphone</li>
</ul>

<table class="mt-1">
    <colgroup>
        <col style="width: 200px;" />
        <col style="width: 500px;" />
    </colgroup>
    <tbody>

        <tr>
            <td>API Key:</td>
            <td>
                <!--type="text"-->
                <input type="password" class="form-control" [(ngModel)]="justCallSettings.cUcApiKey" />
            </td>
        </tr>
        
        <tr>
            <td>From Phone Number:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="justCallSettings.cUcFromPhoneNumber" />
            </td>
        </tr>
        
    </tbody>
</table>

<hr class="my-3" />

<button type="submit" class="btn btn-primary" (click)="onSettingsSave()">
    <i class="me-1 fa fa-save" ng-reflect-ng-class="fa fa-save"></i> Save
</button>
import { ABP, AuthService, ConfigStateService, LocalizationService, RoutesService } from '@abp/ng.core';
import { eThemeSharedRouteNames, NavItemsService, UserMenuService } from '@abp/ng.theme.shared';
import { AfterViewChecked, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { JustCallSettingsComponent } from './just-call-settings/just-call-settings.component';
import { eThemeLeptonXComponents, eUserMenuItems } from '@volosoft/abp.ng.theme.lepton-x';
import { eAccountRouteNames } from '@volo/abp.ng.account/public/config';
import { eThemeSharedComponents } from '@abp/ng.theme.shared/extensions';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <app-ttb-header id="ttb-header"></app-ttb-header>
    <div id="error-container"></div>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements AfterViewChecked {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    routes: RoutesService,
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService,
    private settingTabs: SettingTabsService,
    private authService: AuthService,
    private userMenuService: UserMenuService,
    private navItemsService: NavItemsService
  ) {

    var ogNavigateToLogin = this.authService.navigateToLogin.bind(this.authService);
    this.authService.navigateToLogin = () =>
    {
      sessionStorage.setItem("TTB_LoginReturnPath", location.href);
      ogNavigateToLogin();
    };

    this.configStateService.getOne$('currentUser').subscribe(user => {
      if (user.isAuthenticated)
      {
        var removeTrailing = (url) => url.endsWith("/") ? url.substring(0, url.length - 1) : url;
        var clean = (url) => removeTrailing(url.split("?")[0].split("#")[0]);

        var returnTo = sessionStorage.getItem("TTB_LoginReturnPath");
        if (returnTo && clean(location.href) != clean(returnTo))
        {
          location.href = returnTo;
        }
        sessionStorage.removeItem("TTB_LoginReturnPath");
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.fixUpSideAndTopBars();
    });
		this.configStateService.getOne$('currentUser').subscribe(user => {
			if (user.isAuthenticated)
			{
				Sentry.setContext("userInfo", user);
			}
		});
    const newAdminConfiguration: Partial<ABP.Route> = {
      group: 'Global Setup',
      order: 7,
    };
    routes.patch(eThemeSharedRouteNames.Administration, newAdminConfiguration);

    settingTabs.remove([
      "AbpSettingManagement::Menu:Emailing",
      //"AbpIdentity::Menu:IdentityManagement",
      //"AbpAccount::Menu:Account",
      //"AbpFeatureManagement::Permission:FeatureManagement"
    ]);
    settingTabs.add([
      {
        name: "Office365 Email",
        order: 1,
        requiredPolicy: "TTB.Routing",
        component: EmailSettingsComponent
      }
    ]);
    settingTabs.add([
      {
        name: "SMS Providers",
        order: 2,
        requiredPolicy: "TTB.Routing",
        component: JustCallSettingsComponent
      }
    ]);

    const hideObj = { visible: () => false };
    this.userMenuService.patchItem(eUserMenuItems.LinkedAccounts, hideObj);
    this.userMenuService.patchItem(eUserMenuItems.AuthorityDelegation, hideObj);
    this.userMenuService.patchItem(eUserMenuItems.BackToImpersonator, hideObj);
    this.userMenuService.patchItem(eUserMenuItems.SecurityLogs, hideObj);
    this.userMenuService.patchItem(eUserMenuItems.MyAccount, hideObj);
    this.userMenuService.patchItem("Gdpr.GdprNavigation", hideObj);
  }

  private fixUpSideAndTopBars() {
    try {

    const headerTitle = document.getElementById('ttb-header-bottom-title');
    let sidebarContainer = document.querySelector('.lpx-sidebar-container');
    const adminValues = [
      'identity',
      'openiddict',
      'language-management',
      'text-template-management',
      'audit-logs',
      'setting-management',
    ];
    let isCustomerCenter = false;
    let isCalendar = false;

    let sideBarToggle = document.querySelector("#sideBarToggle") as any;

    const loadSideBarState = () => {
      const state = sessionStorage.getItem("LeptonX_SideBar") || "shown";

      const sidebarContainer = document.querySelector('.lpx-sidebar-container');
      const headerImage = document.querySelector(".ttb-header-bottom a img") as any;
      
      sideBarToggle.classList.add("pt-3");

      switch (state) {
        case "shown":
          sideBarToggle.classList.remove("pt-3");
          sidebarContainer.classList.remove("d-none");
          //sideBarToggle.style.left = "180px";
          sideBarToggle.innerHTML = `<svg class="mud-icon-root mud-svg-icon mud-icon-size-medium" style="width: 20px;height: 20px;fill: white;" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="img"><!--!--><path d="M0 0h24v24H0z" fill="none"></path><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></svg>`;
          if (headerImage) {
            //headerImage.style.transform = "";
          }

          sideBarToggle.style.minHeight = "unset";
          sideBarToggle.style.position = "absolute";
          sideBarToggle.style.top = this.router.url.includes('calendar') ? "13px" : "8px";
          sideBarToggle.style.zIndex = 10;
          sideBarToggle.style.padding = "1px";
          //sideBarToggle.style.background = "red";
          sideBarToggle.style.background = "unset";
          sideBarToggle.style.cursor = "pointer";
          //sideBarToggle.style.borderRadius = "10px";
          sideBarToggle.style.borderRadius = "unset";
          sideBarToggle.style.left = "205px";
          sideBarToggle.style.borderLeft = "3px solid white";

          break;
        case "hidden":
          sidebarContainer.classList.add("d-none");
          //sideBarToggle.style.left = "19px";
          sideBarToggle.innerHTML = `<svg class="mud-icon-root mud-svg-icon mud-icon-size-medium" style="width: 20px;height: 20px;fill: white;" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="img"><!--!--><path d="M0 0h24v24H0z" fill="none"></path><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>`;
          if (headerImage) {
            //headerImage.style.transform = "translateX(80px)";
          }

          sideBarToggle.style.position = "unset";
          sideBarToggle.style.top = "unset";
          sideBarToggle.style.zIndex = "unset";
          sideBarToggle.style.borderRadius = "unset";
          sideBarToggle.style.left = "unset";
          sideBarToggle.style.borderLeft = "unset";
          sideBarToggle.style.padding = "10px 6px";
          sideBarToggle.style.background = this.router.url.includes('calendar') ? "rgb(29, 66, 32)" : "rgb(47, 106, 52)";
          sideBarToggle.style.cursor = "pointer";
          sideBarToggle.style.minHeight = "calc(100vh - 35px)";

          break;
      }
    }

    function toggleSideBarState() {
      let state = sessionStorage.getItem("LeptonX_SideBar") || "shown";
      state = state === "shown" ? "hidden" : "shown";

      sessionStorage.setItem("LeptonX_SideBar", state);

      loadSideBarState();
    }

    if (!sideBarToggle) {
      sideBarToggle = document.createElement("div");

      sideBarToggle.id = "sideBarToggle";

      sideBarToggle.classList.add("d-none");
      sideBarToggle.classList.add("d-md-flex");
      sideBarToggle.classList.add("align-items-start");

      sideBarToggle.addEventListener("click", toggleSideBarState);

      const wrapper = document.querySelector("#lpx-wrapper");
      if (wrapper) {
        wrapper.prepend(sideBarToggle);
      } else {
        sideBarToggle = undefined;
      }
    }

    try {
      loadSideBarState();
    } catch (ex) {

    }

    // Lazy disable hiding
    sidebarContainer = undefined;

    if (sideBarToggle) {
      sideBarToggle.style.transform = "";
    }

    if (headerTitle) {
      if (this.router.url.includes('customers')) {
        isCustomerCenter = true;
        headerTitle.innerText = 'Client Partner Center';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('notification-templates')) {
        headerTitle.innerText = 'Notification Templates';
      } else if (this.router.url.includes('closing-ratio-report')) {
        headerTitle.innerText = 'Closing Ratio Report';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('client-sources')) {
        headerTitle.innerText = 'Client Sources';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (adminValues.some(value => this.router.url.includes(value))) {
        headerTitle.innerText = 'Administration';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('franchises')) {
        headerTitle.innerText = 'Franchisees';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('franchisee-users')) {
        headerTitle.innerText = 'Users';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('labor-rates')) {
        headerTitle.innerText = 'Labor Rates';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('sales-tax-rates')) {
        headerTitle.innerText = 'Sales Tax Rates';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('calendar')) {
        isCalendar = true;
        headerTitle.innerText = 'Calendar';
        //sideBarToggle.style.transform = "translateY(-10px)";
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('processing-fees')) {
        headerTitle.innerText = 'Processing Fee Rates';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('franchise-regions')) {
        headerTitle.innerText = 'Regions';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else if (this.router.url.includes('franchise-sales-placeholders')) {
        headerTitle.innerText = 'Sales Events';
        if (sidebarContainer) {
          sidebarContainer.classList.remove('d-block');
          sidebarContainer.classList.add('d-none');
        }
      } else {
        headerTitle.innerText = 'System Dashboard';
        if (sidebarContainer) {
          sidebarContainer.classList.add('d-block');
          sidebarContainer.classList.remove('d-none');
        }
      }
      document.getElementsByClassName("ttb-header-bottom")[0].classList.remove("d-none");
      if (isCustomerCenter) {
        document.getElementById('top-bar-closing-ratio-report-button').classList.remove('d-none');
        document.getElementById('top-bar-calendar-button')?.classList.remove('d-none');
        document.getElementById('top-bar-customer-center-button').classList.add('d-none');
      } else if (isCalendar) {
        document.getElementById('top-bar-closing-ratio-report-button').classList.add('d-none');
        document.getElementById('top-bar-calendar-button')?.classList.add('d-none');
        document.getElementById('top-bar-customer-center-button').classList.remove('d-none');

        document.getElementsByClassName("ttb-header-bottom")[0].classList.add("d-none");
      } else {
        document.getElementById('top-bar-closing-ratio-report-button').classList.add('d-none');
        document.getElementById('top-bar-calendar-button')?.classList.add('d-none');
        document.getElementById('top-bar-customer-center-button').classList.add('d-none');
      }
    }

  } catch (ex) {
    console.log(ex);
  }
  }

  ngAfterViewChecked(): void {
    this.fixUpSideAndTopBars();
  }
}
